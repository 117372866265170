import styles from '../css/app.scss'
import  registerMenu  from './menu.js'
import animation from './animation.js'
import acordeon from './acordeon.js'
import timeTable from './timetable'
import Turbolinks from 'turbolinks'

const main = async () => {
    // Async load modules
    const lazysizes = await import(/* webpackChunkName: "lazysizes" */ 'lazysizes')
    // const Turbolinks = await import(/* webpackChunkName: "turbolinks" */ 'turbolinks')

    registerMenu()
    registerVideoPlayer()
    if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual'
      }
    Turbolinks.start()
    bindMap()
    animation()
    acordeon()
    timeTable()
    popUp()

    document.addEventListener('turbolinks:load', () => {
        console.log('Loaded')
        registerMenu()
        registerVideoPlayer()
        bindMap()
        animation()
        acordeon()
        timeTable()
        popUp()
    })

    const  { geochat }   = await import(/* webpackChunkName: "geochat" */ './geochat/geochat.jsx')
    geochat()
}

// Execute async function
main().then((value) => {
})

// Binding events
const bindMap = async () => {
  let mapContainer = document.querySelector('#map-container')
  if (mapContainer) {
    const { map } = await import(/* webpackChunkName: "maps" */ './map')
    map()
  }
}

// My functions
function registerVideoPlayer() {
  var buttons = document.querySelectorAll('.programme-video')
  buttons.forEach((button) => {
      const video = button.querySelector('.programme-list-el-profile-video')
      button.addEventListener('mouseenter', () => {
          var playPromise = video.play()
          if (playPromise !== undefined) {
            playPromise.then(_ => {
                video.play()
            })
            .catch(error => {
              console.log(error)
            })
          }
      })
      button.addEventListener('mouseleave', () => {
          video.pause()
      })
  })
}

function popUp() {
  let closeButton = document.querySelector('.redirect-popup-close')
  closeButton.addEventListener('click',()=> {
    closeButton.parentElement.parentElement.classList.add('redirect-popup--close')
  })
  let closeButtonx = document.querySelector('.redirect-popup-close-link')
  closeButtonx.addEventListener('click',(event)=> {
    event.preventDefault()
    closeButtonx.parentElement.parentElement.parentElement.classList.add('redirect-popup--close')
  })
}


