export default function registerMenu () {
    const button = document.querySelector('.nav-menu-button')
    const buttonClose = document.querySelector('.nav-menu-close-button')
    const container = document.querySelector('.nav-secondary-menu')
    if (button && container && buttonClose) {
        button.onclick = e => {
            container.classList.toggle('js-visible')
            document.body.classList.toggle('js-body-noscroll')
        }
        buttonClose.onclick = e => {
            container.classList.toggle('js-visible')
            document.body.classList.toggle('js-body-noscroll')
        }
    }
}
