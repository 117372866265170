export default function animation() {
 let containers = document.querySelectorAll('.sidebar-animation')
 containers.forEach(container => {
    container.querySelectorAll('span').forEach((span, i) => {
        setTimeout(() => {
            span.style.animation = '0.3s ease 0s normal forwards 1 fadein'
            span.style.WebkitAnimation = '0.3s ease 0s normal forwards 1 fadein'
        }, i * 100)
    })
 })
}
