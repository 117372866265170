import ScrollBooster from 'scrollbooster'

export default function timeTable() {
    const container = document.querySelector('.schedule-scroll-container')
    const content = document.querySelector('.schedule-content')
    const background = document.querySelector('.schedule-background')
    const legendBackground = document.querySelector('.schedule-legend-background')
    const legendContainer = document.querySelector('.schedule-legend-container')
    if (container) {
        let sb = new ScrollBooster({
            viewport: container,
            mode: 'x',
            bounce: true,
            onUpdate: (data) => {
                content.style.transform = `translateX(${-data.position.x}px)`
                background.style.transform = `translateX(${-data.position.x}px)`
                legendBackground.style.transform = `translateX(${-data.position.x}px)`
                window.sessionStorage.setItem('scheduleScroll', data.position.x)
              }
        })

        sb.setPosition({
            x: window.sessionStorage.getItem('scheduleScroll'),
            y: 0
          })

        window.addEventListener('scroll', (e) => {
            let viewportOffset = container.getBoundingClientRect()
            console.log(viewportOffset.top)
            if (viewportOffset.top < 40) {
                legendContainer.classList.add('js-sheduele-fixed')
            } else {
                legendContainer.classList.remove('js-sheduele-fixed')
            }
        })
    }
}

